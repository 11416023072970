import { Button, Form } from "antd";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { serviceApi } from "Reducers/api";
import { rootAction, useAppDispatch } from "Reducers/hook";
import { RootRouter } from "Routers/root";
import { getValuesFormData, IsAuthen } from "Utils";

const LoginPage: React.FC = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [ apiLogin, apiLoginState ] = serviceApi.usePostLoginMutation();
    const refForm = useRef<HTMLFormElement | null>(null);

    useEffect(() => {
        if (IsAuthen()) {
            navigate(RootRouter.Home)
        }
    }, [])

    const submitLogin = (e: React.FormEvent) => {
        e.preventDefault();

        if(refForm.current){
            let values = getValuesFormData(refForm.current);

            apiLogin(values).unwrap().then((response: any) => {
                dispatch(rootAction.access.logIn(response.token));
                navigate(RootRouter.Home);
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    return <React.Fragment>
        <form className="login-container" onSubmit={submitLogin}
            ref={refForm}
        >
            <div className="well-login">
                <div className="control-group">
                    <div className="controls">
                        <div>
                            <input name="username" type="text" placeholder="Tên tài khoản hoặc email" className="login-input user-name" />
                        </div>
                    </div>
                </div>
                <div className="control-group">
                    <div className="controls">
                        <div>
                            <input name="password" type="password" placeholder="Mật khẩu" className="login-input user-pass" />
                        </div>
                    </div>
                </div>
                <div className="clearfix">
                    {/* <button className="btn btn-inverse login-btn" type="submit" data-loading-text="loading..." id="fat-btn">Login</button> */}
                    <Button className="btn btn-inverse login-btn" htmlType="submit">Đăng nhập</Button>
                </div>
                <div className="remember-me">
                    <input className="rem_me" type="checkbox" value="" style={{margin: '0px'}} /> Lưu
                </div>
            </div>
        </form>
    </React.Fragment>
}

export default LoginPage