import accessSlice from "./Features/access";
import themeOption from "./Features/themeOption";
import { Canvas } from "./CanvasLayout/CanvasLayout";
import monitorSlice from "./Pages/monitor";
import resourceSlice from "./Pages/resource";
import playerSlice from "./Pages/player";
import { playerSliceLog } from "./Pages/player";
import campaignSlice from "./Pages/campaign";
import { scheduleSlice } from "./Pages/schedule";
import groupSlice from "./Pages/group";
import companySlice from "./Pages/company";
import accountsSlice from "./Pages/accounts";
import AuthSlice from "./Pages/auth";

export default {
    [themeOption.name]: themeOption.actions,
    [accessSlice.name]: accessSlice.actions,
    [Canvas.name]: Canvas.actions,
    [monitorSlice.name]: monitorSlice.actions,
    [resourceSlice.name] : resourceSlice.actions,
    [playerSlice.name]: playerSlice.actions,
    [playerSliceLog.name]: playerSliceLog.actions,
    [campaignSlice.name]: campaignSlice.actions,
    [scheduleSlice.name]: scheduleSlice.actions,
    [groupSlice.name] : groupSlice.actions,
    [companySlice.name]: companySlice.actions,
    [accountsSlice.name]: accountsSlice.actions,
    [AuthSlice.name]: AuthSlice.actions
}