export const RootRouter = {
    Auth: {
        Login: "/login",
        Register: "/register"
    },
    Home: "/",
    Monitor: "/",
    Product: "/product",
    PlayerPage: '/player',
    CampaignPage: '/composer',
    ResourcePage: '/resource',
    Preview: '/preview',
    AccountPage: '/account',
    CompanyPage: '/company',
    AuthPage: '/auth',
    App: {
        Preview: "/app/preview"
    }
};