import { lazy } from "react";

import LoginPage from './Auth/login'
const HomePage = lazy(() => import('./home'));
const NotfoundPage = lazy(()=> import("./404"));
const MonitorPage = lazy(()=> import('./Monitor'));
const PlayerPage = lazy(()=> import('./Player'));
const CampaignPage = lazy(()=> import('./Composer'));
const ResourcePage = lazy(()=> import('./Resource'));
const AccountPage = lazy(()=> import('./Accounts'));
const AppPreviewPage = lazy(()=> import('./App/preview'));
const CompanyPage = lazy(()=> import('./Company'));
const AuthPage = lazy(()=> import('./Auth/AuthPage'));

export { HomePage, LoginPage, NotfoundPage, MonitorPage, PlayerPage,CampaignPage,ResourcePage,AccountPage,CompanyPage, AppPreviewPage,AuthPage };
