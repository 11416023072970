import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

const initialState = {
    backgroundColor: 'bg-white sidebar-text-dark',
    headerBackgroundColor: 'bg-primary header-text-light',
    enableMobileMenuSmall: false,
    enableBackgroundImage: true,
    enableClosedSidebar: false,
    enableFixedHeader: true,
    enableHeaderShadow: true,
    enableSidebarShadow: true,
    enableFixedFooter: true,
    enableFixedSidebar: true,
    colorScheme: 'white',
    backgroundImage: "",
    backgroundImageOpacity: 'opacity-06',
    enablePageTitleIcon: true,
    enablePageTitleSubheading: true,
    enablePageTabsAlt: false,
    avatarDefault: "",
    enableMobileMenu: false,
    closedSmallerSidebar: false
}

export const themeOptionSlice = createSlice({
    name: "themeOption",
    initialState,
    reducers: {
        setEnableClosedSidebar: (state, action: PayloadAction<boolean>) => {
            state.enableClosedSidebar = action.payload;
        },
        setEnableMobileMenu: (state, action: PayloadAction<boolean>) => {
            state.enableMobileMenu = action.payload;
        },
        setEnableMobileMenuSmall: (state, action: PayloadAction<boolean>) => {
            state.enableMobileMenuSmall = action.payload;
        }
    },
    extraReducers: (builder) => {

    }
})

export default themeOptionSlice;