import React, { Fragment, useEffect, Suspense } from "react";
import { Outlet } from "react-router-dom";
import ResizeDetector from 'react-resize-detector';
import { rootAction, useAppDispatch, useAppSelector } from "Reducers/hook";
import cx from "classnames";

import AppLoadingScreen from "./AppLoading";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import AppSidebar from "./AppSidebar";

import { IsAuthen } from "Utils";
import { serviceApi } from "Reducers/api";

const MainLayout: React.FC = (props) => {
    const { themeOption, access } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();

    const [getUserDetail, getUserDetailState] = serviceApi.useGetLoginMutation();

    useEffect(() => {
        if (IsAuthen()) {
            getUserDetail(1)
        } else {
            dispatch(rootAction.access.logOut())
        }
    }, [])


    return !access.UserLogin ?
        <AppLoadingScreen /> : <React.Fragment>
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Fragment>
                        <AppHeader />
                        <AppSidebar />
                        <Suspense fallback={<div style={{ display: 'block' }} className="loading-screen bg-primary"></div>}>
                            <div id="main-content">
                                <div className="container-fluid">
                                    <Outlet />
                                </div>
                            </div>
                        </Suspense>
                        <AppFooter />
                    </Fragment>
                )}
            />
        </React.Fragment >
}

export default MainLayout