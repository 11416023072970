import React, {Fragment} from 'react';

const AppFooter: React.FC = (props) => {
    return (
        <Fragment>
            
        </Fragment>
    )
}

export default AppFooter;