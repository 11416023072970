import { configureStore } from "@reduxjs/toolkit"
import { serviceApi } from "./api";
import { rtkActionMiddleware, rtkQueryErrorLogger } from "./middleware";
import rootReducer from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(serviceApi.middleware, rtkActionMiddleware, rtkQueryErrorLogger)
})

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>

export default store;