import moment from "moment";
import { ConfigApp } from "./contants";
import lodash from "lodash";
import { toast } from "react-toastify";
import { AnyAction } from "@reduxjs/toolkit";
import Resizer from "react-image-file-resizer";

export const IsAuthen = () => getCookie(ConfigApp.nameAuth) ? true : false;

export const SetAuthCookie = (value: any) => setCookie(ConfigApp.nameAuth, value, 30);
export const GetAuthCookie = () => getCookie(ConfigApp.nameAuth);
export const ClearAuthCookie = () => setCookie(ConfigApp.nameAuth, null, -7);

export const CreateRequestOption = (method?: string, params?: any) => {
    var headers = {};

    if (IsAuthen()) {
        headers = {
            'Content-Type': 'application/json',
            'auditlog': localStorage.getItem(ConfigApp.auditLog),
            'Authorization': getCookie(ConfigApp.nameAuth)
        };
    }
    else {
        headers = {
            'Content-Type': 'application/json',
        };
    }

    return {
        method: method,
        headers: headers,
        credentials: 'include',
        body: params && method?.toLocaleLowerCase() != "get" ? JSON.stringify(params) : null
    };
}


export function setCookie(name: string, value: any, days?: any, domain?: any) {
    var expires = "";
    if (days) {
        expires = "; expires=" + moment().add(days, "day").toLocaleString();
    }
    document.cookie = name + "=" + (value || "") + expires + ";domain=" + (domain || "") + "; path=/";
    if (days && days <= 0 && window.location.hostname.split(".").length >= 3) {
        domain = "." + window.location.hostname.split(".").splice(window.location.hostname.split(".").length - 2).join(".")
        document.cookie = name + "=" + (value || "") + expires + ";domain=" + (domain || "") + "; path=/";
    }
}
export function getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return "";
}

export function indexPagination(index: number, pagination?: {
    limit?: number,
    page?: number
}) {
    return pagination && pagination.limit && pagination.page ? (pagination.limit * pagination.page) - pagination.limit + index + 1 : index;
}

export const formatNumber = (num?: number | unknown) => {
    let formatter = Intl.NumberFormat("en", {
        notation: "compact",
    });
    return typeof num != "number" ? "-" : formatter.format(num || 0);
}

export const formatPrice = (price?: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'VND'
    });
    return price && formatter.format(price)
}

export const mergeParamsToUrl = (url: string, params: object) => {
    Object.keys(params).map((key) => {
        url = url.replace(new RegExp(`:${key}`, 'gi'), lodash.get(params, key))
    })
    return url;
}

export const copyText = (text?: string) => {
    if (text) {
        let el_copy = document.querySelector("#input_copy_text");
        if (!el_copy) {
            el_copy = document.createElement("textarea");
            el_copy.setAttribute("id", "input_copy_text");
            el_copy.classList.add("opacity-0");

            document.body.appendChild(el_copy);

            el_copy = document.querySelector("#input_copy_text");
        }
        if (el_copy) {
            el_copy.textContent = text;
            el_copy.select();
            document.execCommand("copy");
            el_copy.remove();
        }
        let id = toast("Sao chép thành công!", { position: "top-center", hideProgressBar: true, closeOnClick: false, className: 'bg-success text-white' })
        setTimeout(() => toast.dismiss(id), 2000)
    }
}

export const formatLiveTime = (time: any) => {
    return time ? `${Math.trunc(moment.duration(time).asHours())}h ${moment.duration(time).minutes()}m ${moment.duration(time).seconds()}s` : "0s"
}

export const getValuesFormData = (e: HTMLFormElement) => {
    let formData = new FormData(e);
    let values: any = {};

    formData.forEach((val, key) => values[key] = val);

    return values;
}

// Format ngày tháng giờ phút giây
export const formattedDateTime = (timestamp: number) => {
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
}

// Format  giờ phút giây ngày tháng năm
export const formattedDateTimeMo = (timestamp: number) => {
    return moment(timestamp).format('HH:mm:ss DD-MM-YYYY')
}

// Format ngày tháng 
export const formattedDate = (timestamp: number) => {
    return moment(timestamp).format('YYYY-MM-DD')
}

// tìm ucln 
export var gcd: any = function (a: number, b: number) {
    if (!b) {
        return a;
    }
    return gcd(b, a % b);
}


// tìm transform: scale(x)
export function calculateScaleRatio(originalWidth: number, originalHeight: number, targetWidth: number, targetHeight: number) {
    let scaleRatio;

    if (originalHeight < originalWidth) {
        scaleRatio = Math.min(targetWidth / originalWidth, targetHeight / originalHeight);
    } else {
        scaleRatio = Math.min(targetHeight / originalHeight, targetWidth / originalWidth);
    }

    return Math.floor(scaleRatio * 100) / 100;
}
// random id 
export function getUid() {
    return Math.random().toString(36).substring(2, 9);
}

// Type Edit playlist
export function TypeEdit(props: string) {
    switch (props) {
        case "coverHybrid":
            return 'image - video'
            break;
        case "coverImage":
            return 'image'
            break;
        case "coverWebsite":
            return 'Website'
            break;
        case "coverVideo":
            return 'video'
            break;
        case "coverText":
            return 'Text'
            break;
        case "coverTime":
            return 'Time'
            break;
        default:
            // console.log(`không có ${props}`);
            break;
    }
}

export function postMessageApp(data_msg: any) {
    try {
        if ((window as any).ReactNativeWebView) {
            window.postMessage(data_msg, "*");
            (window as any).ReactNativeWebView.postMessage(data_msg)
        } else {
            window.postMessage(data_msg, "*");
        };
    } catch (err: any) {
        // alert(`Error post message app: ${err.message}`);
    }
}

export const RandomUid = () => {
    return ("000000000000" + (Math.random() * Math.pow(36, 12)).toString(36)).substr(-12);
}

export const GetPlayerId = () => {
    try {
        let playerId = localStorage.getItem(ConfigApp.namePlayerId);
        if (!playerId) {
            playerId = SetPlayerId();
        }
        return playerId
    } catch (err: any) {
        // alert(`Get Player Id: ${err.message}`);
    }
}

export const SetPlayerId = (value?: string) => {
    let playerId = localStorage.getItem(ConfigApp.namePlayerId) || value || `QL_${RandomUid()}`.toUpperCase();

    localStorage.setItem(ConfigApp.namePlayerId, playerId);

    return playerId
}


// byte to KB , MB , GB
export const formatFileSize = (fileSize: number, type: number) => {
    if (type === 1) {
        if (fileSize < 1024) {
            return fileSize + ' B';
        } else if (fileSize < 1024 * 1024) {
            return (fileSize / 1024).toFixed(2) + ' KB';
        } else if (fileSize < 1024 * 1024 * 1024) {
            return (fileSize / (1024 * 1024)).toFixed(2) + ' MB';
        } else {
            return (fileSize / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }
    } else if (type === 0) {
        if (fileSize < 1024) {
            return fileSize;
        } else if (fileSize < 1024 * 1024) {
            return (fileSize / 1024).toFixed(2);
        } else if (fileSize < 1024 * 1024 * 1024) {
            return (fileSize / (1024 * 1024)).toFixed(2);
        } else {
            return (fileSize / (1024 * 1024 * 1024)).toFixed(2);
        }
    }
}


// sum array number
export const sumArray = (arr: number[]) => {
    let sum = 0;
    for (const number of arr) {
        sum += number;
    }
    return sum;
}


// export const isMatchFulfilledActionApi = (name: string, action: AnyAction) => {
//     return action.type.endsWith('/fulfilled') && action.meta.arg.endpointName == name
// }

// size ảnh
function loadImageBlobFromUrl(imageUrl: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');

            if (!ctx) {
                reject(new Error('Failed to get 2D context from canvas'));
                return;
            }

            ctx.drawImage(img, 0, 0);

            // Convert the canvas content to a Blob
            canvas.toBlob(
                blob => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('Failed to convert canvas to Blob'));
                    }
                },
                img.src.endsWith('png') ? 'image/png' : 'image/jpeg', // Adjust the format based on your needs
                1 // Quality (for JPEG, 0.0 to 1.0)
            );
        };

        img.onerror = () => {
            reject(new Error('Failed to load image'));
        };

        img.crossOrigin = 'anonymous'; // Enable cross-origin resource sharing if needed
        img.src = imageUrl;
    });
}

export const resizeFile = async (imageUrl: string) => {
    try {
        // // tạo blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // const url = 'https://example.com/?name=John&age=25';

        // const url = new URL(imageUrl);
        // const params = new URLSearchParams(url.pathname);
        // const data = params.get('name');

        // const blob = data !== null ? new Blob([data]) : null;
        return new Promise((resolve, reject) => {
            // Resize ảnh
            Resizer.imageFileResizer(
                blob,
                150, // New width
                100, // New height
                'JPEG', // New format
                100, // New quality
                0, // Rotation
                (uri) => {
                    resolve(uri);
                },
                'base64' // Output type
            );
        });
        // return loadImageBlobFromUrl(imageUrl)
        //     .then(blob => {
        //         return new Promise((resolve, reject) => {
        //             // Resize ảnh
        //             Resizer.imageFileResizer(
        //                 blob,
        //                 150, // New width
        //                 100, // New height
        //                 'JPEG', // New format
        //                 100, // New quality
        //                 0, // Rotation
        //                 (uri) => {
        //                     resolve(uri);
        //                 },
        //                 'base64' // Output type
        //             );
        //         });

        //     })


    } catch (error) {
        console.error(error);
        throw error;
    }
};

