import React, { useEffect } from 'react';
import { AllRouter } from 'Routers';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter(AllRouter);

const App: React.FC = () => {
  return (
    <React.Fragment>
      <RouterProvider
        router={router}
      />
    </React.Fragment>
  );
}

export default App;
