import { createSlice } from "@reduxjs/toolkit"
import { serviceApi } from "Reducers/api"
import { Tplayer } from "Types/example"


interface initialState {
    listData: typeof Tplayer[],
    total: number,
    limit: number,
    page: number
}
const initialState: initialState = {
    listData: [],
    total: 0,
    limit: 0,
    page: 1
}

export const playerSlice = createSlice({
    name: "player",
    initialState,
    reducers: {
        list: (state) => {
        },
        updateCampaign: (state) => {

        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(serviceApi.endpoints.getPlayerList.matchFulfilled, (state, action) => {
            state.listData = action.payload.data;
            state.total = action.payload.total;
            state.limit = action.payload.limit;
            state.page = action.payload.page;
        })
    },
})
export const playerSliceLog = createSlice({
    name: "playerLog",
    initialState,
    reducers: {
        log: (state,) => {
        },

    },
    extraReducers: (builder) => {
        builder.addMatcher(serviceApi.endpoints.getPlayerLog.matchFulfilled, (state, action) => {
            state.listData = action.payload.data;
            state.total = action.payload.total;
            state.limit = action.payload.limit;
            state.page = action.payload.page;
        });
    },
})

export default playerSlice;
