import { isRejectedWithValue, PayloadAction, isFulfilled } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { serviceApi } from './api';
import { rootAction } from './hook';
import { ClearAuthCookie, SetAuthCookie } from 'Utils';
import { RootRouter } from 'Routers/root';
import { ArgsProps } from "antd/es/notification/interface";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn('We got a rejected action!', action)
      notification.warning({
        message: "Thông báo",
        description: action.payload.data?.message || "Đã có lỗi xảy ra vui lòng thử lại sau!",
        key: action.type
      })
      if (action.payload.status == 401) {
        ClearAuthCookie();
        return window.location.href = RootRouter.Auth.Login + "?error=" + action.payload.data.message;
      }
    } else if (isFulfilled(action)) {
      let dataNoti = {
        is_show: false,
        config: <ArgsProps>{
          message: "Thông báo",
          description: "Thao tác thành công!"
        }
      };

      switch (action.meta.arg.endpointName) {
        // case serviceApi.endpoints.postLogin.name:
        //   dataNoti.is_show = true;
        //   break
        default:
          break
      }

      if (dataNoti.is_show) notification.success(dataNoti.config);

    }

    return next(action)
  }

export const rtkActionMiddleware: Middleware = () => (next) => (action) => {
  if (process.env.NODE_ENV == "development") console.warn("rtk action middleare", action)

  switch (action.type) {
    case rootAction.access.logOut.type:
      ClearAuthCookie();
      return window.location.href = RootRouter.Auth.Login;
    case rootAction.access.logIn.type:
      SetAuthCookie(action.payload);
      break
    default:
      break
  }
  return next(action);
}