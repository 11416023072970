import { createAction, createSlice } from "@reduxjs/toolkit"
import { serviceApi } from "Reducers/api"

interface initialStateType {
    UserLogin?: {
        company?:{
            company_name?: string;
            is_system?: boolean;
            address?: string,
            status?: number,
            _id?: string,
            user?: string,
            phone?: string,
            email?: string,
            role_menus: number[]
        }
        fullname: string,
        avatar?: string,
        role?: {
            name: string,
            display_name: string,
            actions: string[],
            menu_access: string[]
        },
        _id: string
    },
    token: string,
    Setting: any,
    isNetworkOnline: boolean
}

const initialState: initialStateType = {
    UserLogin: undefined,
    token: '',
    Setting: {

    },
    isNetworkOnline: true
}

export const accessSlice = createSlice({
    name: "access",
    initialState,
    reducers: {
        logOut: (state) => {
            state.UserLogin = undefined;
        },
        logIn: (state, action) => {
            
        },
        handleStatusNetwork: (state, action) => {
            state.isNetworkOnline = action.payload
        },
        list: (state, action) => {
            state.UserLogin = action.payload  
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(serviceApi.endpoints.getLogin.matchFulfilled, (state, action) => {
            state.UserLogin = action.payload.data;
            state.token = action.payload.token;
        })
    }
})

export default accessSlice;