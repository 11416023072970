import React, { Fragment, useState } from 'react';
import { rootAction, useAppDispatch, useAppSelector } from 'Reducers/hook';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import ModalChangePassword from 'Components/Modal/ModalChangePassword';

const AppHeader: React.FC = (props) => {
    const { UserLogin } = useAppSelector((state) => state.access);
    const dispatch = useAppDispatch();

    const [toggleMenuUser, setToggleMenuUser] = useState(false);
    const [changePasswordUser, setChangePasswordUser] = useState({
        visible: false
    })
    const [toggleMenuLanguage, setToggleMenuLanguage] = useState(false);

    const LogOut = () => {
        dispatch(rootAction.access.logOut())
    }

    return <Fragment>
        <div className="navbar navbar-fixed-top">
            <div className="navbar-inner top-nav">
                <div className="container-fluid">
                    <div className="branding">
                        <div className="logo">
                            <Link to="/"><img src="img/logo.png" width="168" height="40" alt="Logo" /></Link>
                        </div>
                    </div>
                    <ul className="nav pull-right">
                        <Dropdown isOpen={toggleMenuLanguage} toggle={() => setToggleMenuLanguage(!toggleMenuLanguage)} direction={"down"} nav={true}>
                            <DropdownToggle nav={true} caret={true}>
                                <i className="lang-icons"><img src="img/flag-icons/vn.png" width="16" height="11" alt="language" /></i><b className="caret"></b>
                            </DropdownToggle>
                        </Dropdown>
                        <Dropdown isOpen={toggleMenuUser} toggle={() => setToggleMenuUser(!toggleMenuUser)} direction={"down"} nav={true}>
                            <DropdownToggle nav={true} caret={true}>
                                {UserLogin?.fullname}
                                <span className="alert-noty">0</span><i className="white-icons admin_user"></i><b className="caret"></b>
                            </DropdownToggle>
                            <DropdownMenu container="body">
                                <li className='disabled'><a href="#"><i className="icon-envelope"></i> Thông báo <span className="alert-noty">0</span></a></li>
                                {/* <li className='disabled'><a href="#"><i className="icon-briefcase"></i> Tài khỏan</a></li> */}
                                <li className='disabled disabled_click' style={{cursor: 'pointer'}} onClick={() => setChangePasswordUser({ visible: true })}>
                                    <i className="icon-file"></i>Thay đổi mật khẩu
                                </li>
                                <li className='disabled' style={{cursor: 'pointer'}}><Link to='/auth' ><i className="icon-cog"></i> Cài đặt tài khoản</Link></li>
                                <li className='div_divider'>
                                </li>
                                <li><a href="#" onClick={LogOut}><i className="icon-off"></i><strong> Đăng xuất</strong></a></li>
                            </DropdownMenu>
                        </Dropdown>
                        {/* <li className="dropdown"><a data-toggle="dropdown" className="dropdown-toggle" href="#">{UserLogin?.fullname} <span className="alert-noty">0</span><i className="white-icons admin_user"></i><b className="caret"></b></a>
                            <ul className="dropdown-menu">
                                <li><a href="#"><i className="icon-inbox"></i> Inbox <span className="alert-noty">10</span></a></li>
                                <li><a href="#"><i className="icon-envelope"></i> Notifications <span className="alert-noty">15</span></a></li>
                                <li><a href="#"><i className="icon-briefcase"></i> My Account</a></li>
                                <li><a href="#"><i className="icon-file"></i> View Profile</a></li>
                                <li><a href="#"><i className="icon-pencil"></i> Edit Profile</a></li>
                                <li><a href="#"><i className="icon-cog"></i> Account Settings</a></li>
                                <li className="divider"></li>
                                <li><a href="#"><i className="icon-off"></i><strong> Logout</strong></a></li>
                            </ul>
                        </li> */}
                    </ul>
                    <button data-target=".nav-collapse" data-toggle="collapse" className="btn btn-navbar" type="button"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                    <div className="nav-collapse collapse">
                        <ul className="nav">

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <ModalChangePassword
            {...changePasswordUser}
            onCancel={()=> setChangePasswordUser({visible: false})}
        />
    </Fragment>
}

export default AppHeader;