import { fabric } from 'fabric';
import { createSlice } from "@reduxjs/toolkit"
import { calculateScaleRatio } from '../../Utils/index';

interface TState {
  canvas: fabric.Canvas | null,
  canvasEdit: any,
  videos: [],
  images: [],
  audios: [],
  editorElements: [],
  backgroundColor: string,
  maxTime: number,
  playing: false,
  currentKeyFrame: number,
  selectedElement: string | null,
  fps: number,
  animations: [],
  selectedMenuLayout: string | null,
  screenHeight: number,
  screenWidth: number,
  CanvasZoom: number,
  svg: any,
  contentObject: any,
  contentObjectTemporary: any,
  activeGroup: number,
  reset: boolean,
  id: string,
  activeTab: boolean,
  ladoSvg: boolean,
  ladoSvgCanvas: boolean,
  group_id: string
}
// initialState variables
const initialState: TState = {
  canvas: null,
  canvasEdit: null,
  videos: [],
  images: [],
  audios: [],
  editorElements: [],
  backgroundColor: '#111111',
  maxTime: 30 * 1000,
  playing: false,
  currentKeyFrame: 0,
  selectedElement: '',
  fps: 60,
  animations: [],
  selectedMenuLayout: null,
  screenHeight: 320,
  screenWidth: 240,
  CanvasZoom: 100,
  svg: null,
  contentObject: {},
  activeGroup: 0,
  reset: false,
  id: '',
  activeTab: true,
  ladoSvg: false,
  ladoSvgCanvas: false,
  contentObjectTemporary: {},
  group_id: ''
}

// create slice
export const Canvas = createSlice({
  name: 'Canvas',
  initialState,
  reducers: {
    setMenuLayout(state, selectedMenuLayout) {
      state.selectedMenuLayout = selectedMenuLayout.payload
    },
    setActiveTabLayout(state, tab) {
      state.activeTab = tab.payload
    },
    setgGroupId(state, groupId) {
      state.group_id = groupId.payload
    },
    setSvg(state, svg) {
      state.svg = svg.payload
    },
    setSreen(state, screen) {
      state.screenHeight = screen.payload[0]
      state.screenWidth = screen.payload[1]
    },
    setActiveGroup(state, active) {
      state.activeGroup = active.payload
    },
    setResetLayout(state, active) {
      state.reset = active.payload
    },
    setCanvas(state, canvas: any) {
      state.canvas = JSON.parse(JSON.stringify(canvas.payload));
      state.ladoSvgCanvas = !(state.ladoSvgCanvas)
    },
    setIdComposer(state, active) {
      state.id = active.payload
    },
    setContentObject(state, object) {
      state.contentObject = object.payload;
    },
    setCanvasZoom(state, zoom) {
      if (state.CanvasZoom) {
        state.CanvasZoom = zoom.payload;
      }
    },
    setContentObjectTemporary(state, object) {
      state.contentObjectTemporary = object.payload;
    },
    setCanvasEdit(state, canvasEdit) {
      state.canvasEdit = JSON.parse(JSON.stringify(canvasEdit.payload))
      state.ladoSvg = !state.ladoSvg
    },
    setBoxCanvas(state, boxCanvas) {
      const contentObject = JSON.parse(JSON.stringify(state.contentObject))
      const canvasEdit = JSON.parse(JSON.stringify(state.canvasEdit))
      if (contentObject?.box) {
        const updatedBox = [...boxCanvas.payload];
        contentObject.box = updatedBox;
        state.contentObject = contentObject
        if (canvasEdit?.objects) {
          const updatedA = {
            ...canvasEdit,
            objects: canvasEdit.objects.map((obj: any) => obj.id === contentObject.id ? { ...obj, ...contentObject } : obj)
          };
          state.canvasEdit = updatedA
          state.ladoSvg = !state.ladoSvg
        }
      }
    },
    setSelectedElement(state: any, selectedElement: any) {
      if (state.selectedElement) {
        state.selectedElement = selectedElement;
      }
      const canvas = state.canvas
      if (state.canvas) {
        if (selectedElement?.fabricObject)
          canvas.setActiveObject(selectedElement.fabricObject);
        else
          canvas.discardActiveObject();
      }
    },
    SaveCanvasToSVG(state, canvasEdit) {

      var sacle = calculateScaleRatio(state.screenWidth, state.screenHeight, 900, 450)
      const newCanvas = canvasEdit.payload?.objects?.map((item: any, index: any) => { return { ...item, index: index } })
      const objectCanvasVideos = newCanvas?.filter((item: { name: string; }) => item.name === 'coverVideo')
      const objectCanvasImg = newCanvas?.filter((item: { name: string; }) => item.name === 'coverImage')
      const objectCanvasWebsite = newCanvas?.filter((item: { name: string; }) => item.name === 'coverWebsite')
      const objectCanvasText = newCanvas?.filter((item: { name: string; }) => item.name === 'coverText')
      const objectHybird = newCanvas?.filter((item: { name: string; }) => item.name === 'coverHybrid')
      const objectCanvasTime = newCanvas?.filter((item: { name: string; }) => item.name === 'coverTime')

      // tính tỉ lệ màn hình
      var height: number = state.screenHeight
      var width: number = state.screenWidth

      // cover video to svg
      const SvgVideo = objectCanvasVideos?.map((i: { box: any; id: string; index: any; width: number; height: number; left: any; top: any; angle: any; scaleX: any; scaleY: any; }) => {

        let box = i?.box
        return (

          `
          <div
          xmlns = "http://www.w3.org/1999/xhtml"
          style = "
          position: fixed;
          left:${(i?.left*100 / sacle)/width}%;
          top: ${(i?.top*100 / sacle)/height}%;
          width: ${((i?.width / sacle))/(width/100)}%;
          height: ${(i?.height / sacle)/(height/100)}%;
          background: url('');
          object-fit: contain;
          transform: rotate(${i?.angle}deg) scale(${i?.scaleX, i?.scaleY});
          background-size: cover;
          transform-origin: left top;
          background-repeat: no-repeat;
          background-position: center;
          z-index: ${i?.index};
          overflow-x: hidden;
          display: flex;
          "
          id=${i?.id}
        >
          ${box.map((e: { url: string }, index: number) => {

            if (e.url.includes('.mp4')) {
              return (
                `
                    <video class="${i.id}" xmlns="http://www.w3.org/1999/xhtml" muted="true"   loop="true" style="position: fixed;transform-origin: left top ; transform: rotate(${i?.angle}deg) scale(${i?.scaleX, i?.scaleY}); object-fit: fill;  z-index: ${i.box.length - index};height: 100%; width: 100%;">
                        <source src="${e?.url}" type="video/mp4" />
                    </video>
               `
              )
            } else if (e.url.includes('.webm')) {
              return (
                `
                  <video class="${i.id}" xmlns="http://www.w3.org/1999/xhtml" muted="true"   loop="true" style="position: fixed;transform-origin: left top ;  transform: rotate(${i?.angle}deg) scale(${i?.scaleX, i?.scaleY}); object-fit: fill; z-index: ${i.box.length - index};height: 100%; width: 100%;">
                      <source src="${e.url}" type="video/webm" />
                  </video>
             `
              )
            } else if (e.url.includes('.ogg')) {
              return (
                `
                  <video class="${i.id}" xmlns="http://www.w3.org/1999/xhtml" muted="true"   loop="true" style="position: fixed;transform-origin: left top ;transform: rotate(${i?.angle}deg) scale(${i?.scaleX, i?.scaleY}); object-fit: fill; z-index: ${i.box.length - index};height: 100%; width: 100%;">
                      <source src="${e.url}" type="video/ogg" />
                  </video>
             `
              )
            } else {
              return (
                `
                    <video class="${i.id}" xmlns="http://www.w3.org/1999/xhtml"  muted="true"   loop="true" style="position: fixed;transform-origin: left top ;  transform: rotate(${i?.angle}deg) scale(${i?.scaleX, i?.scaleY}); object-fit: fill;  z-index: ${i.box.length - index};height: 100%; width: 100%;">
                        <source src="${e?.url}" type="video/mp4" />
                    </video>
               `
              )
            } 
          }
          ).join(' ')}
          </div>
          `
        )
      }).join(' ')

      // cover image to svg
      const SvgImage = objectCanvasImg?.map((i: { index: number; id: string; box: any[]; width: number; src: any; left: any; top: any; height: number; angle: any; scaleX: any; scaleY: any; }) => {
        let ImgNew = i?.src
        let box = i?.box

        return (
          `
        <div
          xmlns = "http://www.w3.org/1999/xhtml"
          style = "
          position: fixed;
          left:${(i?.left*100 / sacle)/width}%;
          top: ${(i?.top*100 / sacle)/height}%;
          width: ${(i?.width*100 / sacle)/width}%;
          height: ${(i?.height*100 / sacle)/height}%;
          background: url('${ImgNew}');
          object-fit: contain;
          transform: rotate(${i?.angle}deg) scale(${i?.scaleX, i?.scaleY});
          background-size: cover;
          transform-origin: left top;
          background-repeat: no-repeat;
          background-position: center;
          z-index: ${i?.index};
          overflow-x: hidden;
          display: flex;
          "
          id=${i?.id}
        >
          ${box?.map((e: { url: any; }, index: number) => (
            `
                  <img 
                  xmlns="http://www.w3.org/1999/xhtml"
                  style="
                  height: 100%;
                  width: 100%;
                  object-fit: fill;
                  position: absolute;
                  z-index: ${i?.box?.length - index}
                  "
                  class="${i?.id}"
                  src="${e.url}" alt="img" />
                  `
          )).join(' ')
          }
        </div>
        `
        )
      }).join(' ')

      //cover iframe to svg
      const SvgIframe = objectCanvasWebsite?.map((i: { id: string; index: any; box: any; width: number; height: number; left: any; top: any; angle: any; scaleX: any; scaleY: any; }) => {
        let box = i?.box
        return (
          `
          <div
          xmlns = "http://www.w3.org/1999/xhtml"
          style = "
          position: fixed;
          left: ${(i?.left*100 / sacle)/width}%;
          top: ${(i?.top*100 / sacle)/height}%;
          width: ${(i?.width*100 / sacle)/width}%;
          height: ${(i?.height*100 / sacle)/height}%;
          background: url('');
          object-fit: contain;
          transform: rotate(${i?.angle}deg) scale(${i?.scaleX, i?.scaleY});
          background-size: cover;
          transform-origin: left top;
          background-repeat: no-repeat;
          background-position: center;
          z-index: ${i?.index};
          overflow-x: hidden;
          display: flex;
          "
          id=${i?.id}
        >
          <body xmlns = "http://www.w3.org/1999/xhtml" style = "position: fixed; transform-origin: left top; width: ${(i?.width*100 / sacle)/width}%; height:${(i?.height*100 / sacle)/height}%; left: ${(i?.left*100 / sacle)/width}% ; top: ${(i?.top*100 / sacle)/height}% ;transform: rotate(${i?.angle}deg) scale(${i.scaleX}, ${i.scaleY});z-index: ${i?.index}; margin: 0px" >
          ${box.map((e: { url: string }, index: number) => (
            `
                  <iframe sandbox class="${i.id}" src="${e.url}" width="100%" style = "pointer-events: none; z-index: ${i.box.length - index} ;position: fixed; transform-origin: left top; height: 100%; width: 100% ;transform: rotate(${i?.angle}deg) scale(${i.scaleX}, ${i.scaleY}); margin: 0px"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" scrolling="no"></iframe>
                `
          )).join(' ')
          }
          </body>
          </div>
          `
        )
      }).join(' ')

      // cover text to svg
      const SvgText = objectCanvasText?.map((i: { box: any; fontWeight: string; lineHeight: string; fontSize: string; id: string; index: any; fontFamily: string; width: number; height: number; left: any; top: any; angle: any; scaleX: any; scaleY: number; }) => {
        let box = i?.box
        return (
          `
          <Text xmlns = "http://www.w3.org/1999/xhtml" style = "overflow: hidden; display: flex;align-items: center;white-space: nowrap;position: fixed; transform-origin: left top; width: ${(i?.width*100 / sacle * i.scaleX)/width}%; height:${(i?.height*100 / sacle * i.scaleY)/height}%; left: ${(i?.left*100 / sacle)/width}% ; top: ${(i?.top*100 / sacle)/height}% ;z-index: ${i?.index}; margin: 0px" >
            <p id="${i?.id}" style="margin: 0px ;display: block; width: fit-content; font-weight: ${i?.fontWeight}; line-height: ${i?.lineHeight}; font-size: ${(i?.height*100 / sacle * i?.scaleY)/height}vh; font-family: ${i?.fontFamily}  ">  
          ${box.map((e: { content: string }) => (
            `${e?.content}`
          )).join(' ')
          }
          </p>
            </Text>
        `
        )
      }).join(' ')

      // cover hybird 
      const Svghybird = objectHybird?.map((i: { index: number; box: any[]; width: number; src: any; left: any; top: any; height: number; angle: any; scaleX: any; scaleY: any; id: string }) => {
        const DivRender = i.box.map((item, index: number) => {
          if (item.type === 'image') {
            return (
              `
                <img 
                xmlns="http://www.w3.org/1999/xhtml"
                style="
                height: 100%;
                width: 100%;
                object-fit: fill;
                position: absolute;
                z-index: ${i?.box?.length - index}
                "
                class="${i?.id}"
                src="${item?.url}" alt="img" />
                `
            )
          }
          else if (item.type === 'video') {
            return (
              `
              <video class="${i?.id}" xmlns="http://www.w3.org/1999/xhtml" muted="true"  loop="true" style="position: absolute; transform-origin: left top; transform: rotate(${i?.angle}deg) scale(${i?.scaleX}, ${i?.scaleY}); object-fit: fill; z-index: ${i.box.length - index}; height: 100%; width: 100%">
              <source src="${item?.url}" type="video/mp4" />
            </video>
              `
            )
          }
        }).join(' ')

        return (
          `
                  <div
                  xmlns = "http://www.w3.org/1999/xhtml"
                  style = "
                  position: fixed;
                  left: ${(i?.left*100 / sacle)/width}%;
                  top: ${(i?.top*100 / sacle)/height}%;
                  width: ${(i?.width*100 / sacle)/width}%;
                  height: ${(i?.height*100 / sacle)/height}%;
                  background: url('');
                  object-fit: fill;
                  transform: rotate(${i?.angle}deg) scale(${i?.scaleX, i?.scaleY});
                  background-size: cover;
                  transform-origin: left top;
                  background-repeat: no-repeat;
                  background-position: center;
                  z-index: ${i?.index};
                  overflow-x: hidden;
                  display: flex;
                  "
                  id=${i?.id}
                >
                ${DivRender}
                </div>
                `
        )
      }).join(' ')

      //cover Time
      const SvgTime = objectCanvasTime?.map((i: { fontWeight: string; lineHeight: string; fontFamily: string; fontSize: string; index: number; box: any[]; width: number; src: any; left: any; top: any; height: number; angle: any; scaleX: any; scaleY: any; id: string }) => {
        var timer = new Date();

        var hour: any = timer.getHours();  //Lấy giờ hiện tại (giá trị từ 0 - 23)
        var minute: any = timer.getMinutes();  //Lấy phút hiện tại
        var second: any = timer.getSeconds();  //Lấy giây  hiện tại
        //Thêm ký tự 0 đằng trước nếu giờ, phút, giây bé hơn 10 với câu lệnh điều khiển if
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (minute < 10) {
          minute = "0" + minute;
        }
        if (second < 10) {
          second = "0" + second;
        }
        var Time = hour + ":" + minute + ":" + second

        return (
          `
          <Text class="${i.id} timer"  xmlns = "http://www.w3.org/1999/xhtml" style = "display: flex;align-items: center; justify-content: center;position: fixed; overflow: hidden; transform-origin: left top; width: ${(i?.width*100 / sacle * i?.scaleX)/width}%; height:${(i?.height*100 / sacle * i?.scaleY)/height}%; left: ${(i?.left*100 / sacle)/width}% ; top: ${(i?.top*100 / sacle)/height}% ;z-index: ${i?.index}; margin: 0px;font-weight: ${i?.fontWeight}; line-height: ${i?.lineHeight}; font-size: ${(i?.height*100 / sacle * i?.scaleY)/height}vh; font-family: ${i?.fontFamily}  " >
              ${Time}
          </Text>
          `
        )
      }).join(' ')

      // svg
      //left: 50%; top: 50%;transform:translate(-50%, -50%)
      const svg = `<svg
        version = "1.1"
        class="center-block"
        xmlns = "http://www.w3.org/2000/svg"
        xmlns:xlink = "http://www.w3.org/1999/xlink"
        style = "position: absolute; width: ${width}px; height: ${height}px; touch-action: none; user-select: none; cursor: default;"
          >
          <g>
          <g transform="">
            <foreignObject x="0" y="0" width="100%" height="100%" style="background: #fff">
            ${SvgIframe ? SvgIframe : ''}
            ${SvgVideo ? SvgVideo : ''}
            ${SvgImage ? SvgImage : ''}
            ${SvgText ? SvgText : ''}
            ${Svghybird ? Svghybird : ''}
            ${SvgTime ? SvgTime : ''}
            </foreignObject>
            </g>
          </g>
          </svg>
          `
      state.svg = svg.toString()

    }
  }
})

// export các action cần dùng
export const { setMenuLayout, setContentObjectTemporary, setgGroupId, setCanvasZoom, setSvg, setIdComposer, setActiveTabLayout, setBoxCanvas, setActiveGroup, setResetLayout, setContentObject, setCanvas, setSelectedElement, SaveCanvasToSVG, setCanvasEdit, setSreen } = Canvas.actions

export default Canvas.reducer

export const selectMenu = (state: { setSelectedMenuOption: any; }) => state?.setSelectedMenuOption