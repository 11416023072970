import { serviceApi } from 'Reducers/api'
import { useEffect, useState, FormEvent } from 'react'
import { Modal, ModalHeader, ModalBody, Button, Form } from 'reactstrap'

interface TProps {
    visible: boolean,
    onCancel: () => void,
}

const ModalChangePassword: React.FC<TProps> = (props) => {
    // props
    const { visible, onCancel } = props

    // state
    const [loading, setLoading] = useState<boolean>(false)
    const [showIcon, setShowIcon] = useState<{ icon1: boolean, icon2: boolean, icon3: boolean }>({
        icon1: false,
        icon2: false,
        icon3: false,
    })
    const [passwordChange, setPasswordChange] = useState<boolean>(false)
    const [value, setValue] = useState<{ password: string, password_old: string, password_change: string }>({
        password: '',
        password_old: '',
        password_change: ''
    })

    useEffect(() => {
        setShowIcon({
            icon1: false,
            icon2: false,
            icon3: false,
        })
        setValue({
            password: '',
            password_old: '',
            password_change: ''
        })
    }, [visible])


    // call api
    const [apiChangPasword, apiChangPaswordState] = serviceApi.usePostChangePasswordMutation();

    // submit
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (value.password_change === value.password) {
            setLoading(true)
            apiChangPasword({ password: value.password, password_old: value.password_old }).unwrap().then((response: any) => {
                setLoading(false)
                onCancel()
            }).catch((err) => {
                setLoading(false)
            })
        }
    }

    return (
        <Modal
            isOpen={visible}
            backdrop={true}
            fade={true}
            size="xl"
            style={{ width: '600px' }}
        >
            <ModalHeader>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    Thay đổi mật khẩu
                    <Button onClick={onCancel}>X</Button>
                </div>
            </ModalHeader>
            <ModalBody>
                <div style={{ display: `${loading ? 'block' : 'none'}` }} className="loading-screen bg-primary"></div>
                <Form className="login-container less" style={{ margin: '20px auto' }}
                    onSubmit={handleSubmit}
                >
                    <div className="well-login">
                        <div className="control-group">
                            <div className="controls">
                                <label style={{ fontWeight: '600' }}>Mật khẩu cũ :</label>
                                <div style={{ position: 'relative' }}>
                                    <i style={{ display: `${showIcon.icon1 ? 'block' : 'none'}` }} className='icon-eye-open icon_close-password' onClick={() => setShowIcon({ ...showIcon, icon1: !showIcon.icon1 })} />
                                    <i style={{ display: `${!showIcon.icon1 ? 'block' : 'none'}` }} className='icon-eye-close icon_close-password' onClick={() => setShowIcon({ ...showIcon, icon1: !showIcon.icon1 })} />
                                    <input
                                        name="password_old"
                                        type={`${showIcon.icon1 ? "text" : "password"}`}
                                        placeholder="Mật khẩu cũ" className="login-input"
                                        value={value.password_old}
                                        onChange={(e) => setValue({ ...value, password_old: e.target.value })}
                                        required
                                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Vui lòng nhập thông tin ở đây')}
                                        onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="control-group">
                            <div className="controls">
                                <label style={{ fontWeight: '600' }}>Mật khẩu mới :</label>
                                <div style={{ position: 'relative' }}>
                                    <i style={{ display: `${showIcon.icon2 ? 'block' : 'none'}` }} className='icon-eye-open icon_close-password' onClick={() => setShowIcon({ ...showIcon, icon2: !showIcon.icon2 })} />
                                    <i style={{ display: `${!showIcon.icon2 ? 'block' : 'none'}` }} className='icon-eye-close icon_close-password' onClick={() => setShowIcon({ ...showIcon, icon2: !showIcon.icon2 })} />
                                    <input
                                        value={value.password}
                                        required
                                        onChange={(e) => {
                                            setPasswordChange(false)
                                            setValue({ ...value, password: e.target.value })
                                        }}
                                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Vui lòng nhập thông tin ở đây')}
                                        onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
                                        name="password" type={`${showIcon.icon2 ? "text" : "password"}`} placeholder="Mật khẩu mới" className="login-input" />
                                </div>
                            </div>
                        </div>
                        <div className="control-group">
                            <div className="controls">
                                <label style={{ fontWeight: '600' }}>Nhập lại khẩu mới :</label>
                                <div style={{ position: 'relative' }}>
                                    <i style={{ display: `${showIcon.icon3 ? 'block' : 'none'}` }} className='icon-eye-open icon_close-password' onClick={() => setShowIcon({ ...showIcon, icon3: !showIcon.icon3 })} />
                                    <i style={{ display: `${!showIcon.icon3 ? 'block' : 'none'}` }} className='icon-eye-close icon_close-password' onClick={() => setShowIcon({ ...showIcon, icon3: !showIcon.icon3 })} />
                                    <input
                                        value={value.password_change}
                                        required
                                        onChange={(e) => {
                                            setPasswordChange(true)
                                            setValue({ ...value, password_change: e.target.value })
                                        }}
                                        onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Vui lòng nhập thông tin ở đây')}
                                        onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
                                        name="password" type={`${showIcon.icon3 ? "text" : "password"}`} placeholder="Nhập lại mật khẩu mới" className="login-input" />
                                    <p style={{ display: `${!(passwordChange && value.password !== value.password_change) ? 'none' : 'block'}` }} className='p_error'>Nhập lại mật khẩu mới không khớp</p>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix">
                            {/* <button className="btn btn-inverse login-btn" type="submit" data-loading-text="loading..." id="fat-btn">Login</button> */}
                            <Button style={{ background: '#006dcc' }} className="btn btn-inverse login-btn" type='submit'>Thay đổi</Button>
                        </div>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )

}
export default ModalChangePassword