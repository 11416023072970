import React from "react";
import { Outlet } from "react-router-dom";

const LoginLayout: React.FC = () => {
    return <React.Fragment>
        <div className="navbar navbar-fixed-top">
            <div className="navbar-inner">
                <div className="container-fluid">
                    <div className="branding">
                        <div className="logo">
                            <a href="index.html"><img src="img/logo.png" width="168" height="40" alt="Logo" /></a>
                        </div>
                    </div>
                    <ul className="nav pull-right">
                        <li><a href="#"><i className="icon-share-alt icon-white"></i> Tới trang web chính</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <Outlet />
    </React.Fragment>
}

export default LoginLayout