import React from 'react';

const AppLoadingScreen: React.FC = (props) => {
    return <div className="loader-container">
        <div className="loader-container-inner">
            <div className="loading-screen bg-primary"></div>
        </div>
    </div>
}

export default AppLoadingScreen